import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { FaClock, FaHandPointDown, FaBriefcase } from "react-icons/fa";
import Columns from "../components/columns";
import BenefitBlock from "../components/benefitBlock";
import SEO from "../components/seo";
import LightBox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import TryButton from "../components/TryButton";

export const query = graphql`
  query {
    pulpit1: file(relativePath: { eq: "prosty-crm.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pulpit2: file(relativePath: { eq: "Pulpit-pracownika-sprzedaz.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pulpit3: file(relativePath: { eq: "Pulpit-pracownika-moje-zadania-.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    pulpit4: file(relativePath: { eq: "Pulpit-pracownika-aktywnosc.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Pulpity = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Dynamiczny pulpit pracownika"
      headline="Stwórz swój własny widok dla pulpitu"
    >
      <SEO
        title="Pulpit pracownika - dostosuj go do swoich potrzeb"
        description="Dynamiczny pulpit pracownika • Decyduj, jakie narzędzia chcesz mieć pod ręką • Korzystaj wygodnie • Oszczędzaj czas • Wypróbuj QuickCRM - 30 dni za darmo"
        keywords={["pulpit pracownika"]}
      />
      <Columns>
        <div>
          <h3>Dynamiczna firma wymaga dynamicznego pulpitu</h3>
          <p>
            Prosty system CRM nie oznacza niewielu funkcji czy narzucania
            jednego standardu pracy.&nbsp;
            <strong>
              QuickCRM oferuje wiele możliwości, spośród których użytkownik może
              wybrać te, które najbardziej mu odpowiadają.
            </strong>
          </p>
          <p>
            Dobrym tego przykładem jest dynamiczny pulpit pracownika, który
            pozwala&nbsp;<strong>uporządkować</strong>&nbsp;codzienną pracę.
            Informacje, których potrzebujesz, masz pod ręką i&nbsp;nie musisz
            już tracić czasu na przetrząsanie całego biura czy dysku w&nbsp;ich
            poszukiwaniu.
          </p>
        </div>
        <Img
          style={{
            maxWidth: 800,
            margin: "0 auto"
          }}
          fluid={data.pulpit1.childImageSharp.fluid}
        />
      </Columns>

      <h3>Co chcesz mieć pod ręką?</h3>
      <p>
        QuickCRM daje możliwość<strong>&nbsp;dostosowania widoku, </strong>
        <strong>
          który zobaczy każdy pracownik po zalogowaniu się do systemu.
        </strong>{" "}
        Jest to miejsce szczególnie ważne, dlatego powinieneś mieć&nbsp;
        <strong>
          możliwość&nbsp;zdecydowania, jakie informacje chcesz tam wyświetlić
        </strong>
        . Powinny to być widgety, z&nbsp;których dany pracownik korzysta
        najczęściej.
      </p>
      <p>
        Jeśli codziennie analizujesz&nbsp;
        <Link to="/raporty/">raporty</Link>&nbsp;— wyświetl je na swoim
        pulpicie. Jeśli chcesz, aby handlowiec miał pod ręką np.&nbsp;kalendarz
        czy listę&nbsp;
        <Link to="/kalendarz-i-zadania/">zaplanowanych zadań</Link> —{" "}
        <strong>
          możesz zaprojektować jego pulpit tak, jak uznasz za stosowne.
        </strong>
      </p>
            <h3
        style={{
          textAlign: "center"
        }}
      >
        Przykładowe pulpity
      </h3>
      <LightBox images={[data.pulpit2, data.pulpit3, data.pulpit4]} 
      alts={["pulpit pracownika w crm, pulpit pracownika w systemie crm, dynamiczny pulpit pracownika w crm"]}/>
      
      <h3
        style={{
          textAlign: "center"
        }}
      >
        <strong>Co zyskujesz?</strong>
      </h3>
      <BenefitBlockWrapper>
        <BenefitBlock
          icon={<FaHandPointDown size="45px" />}
          content="Najważniejsze dane pod ręką"
        />
        <BenefitBlock
          icon={<FaBriefcase size="45px" />}
          content="Narzędzie analizy skuteczności handlowców"
        />
        <BenefitBlock
          icon={<FaClock size="45px" />}
          content="Szybką możliwość zdefiniowania ograniczeń"
        />
      </BenefitBlockWrapper>
    </FunctionPageLayout>
  );
};

export default Pulpity;
